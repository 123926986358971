import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

// Redux
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { postPageView } from 'store/actions/profile'
import { showLoader, hideLoader } from 'store/actions/loader'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'

// Router
import { withRouter } from 'react-router-dom'

// Proptypes
import PropTypes from 'prop-types'

// Components
import DetailHeader from 'components/layouts/DetailHeader'
import { reduxForm, change } from 'redux-form'
import ErrorModal from 'components/elements/ErrorModal'
import NoVehicleModal from './Modal/NoVehicle'
import TILRefinanceBox from './TILRefinanceBox'
import TILRefinanceForm from './TILRefinanceForm'

// Actions
import { getRefinanceDetail, registerTransactionContractRf } from 'store/actions/tilRefinance'
import { getVehicleDetail } from 'store/actions/vehicles'

// Method
import { checkError } from 'utils/helper'
import { paddingZeros } from 'utils/format'

// Constant
import { tilAppKey } from 'config/environment'

// Styles
import './_index.scss'

import { trackRefinanceEvent } from 'utils/treasureData'

// Environment
import { tilWebsiteUrl } from 'config/environment'

import api from '../../api'

const TILRefinance = ({ profile, vehicleDetail, tilRefinance, change, tilRefinanceForm, defaultVehicle }) => {
  const maximumError = 4
  const params = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const errorModal = useRef()
  const errorSubmitModal = useRef()
  const noVehicleModal = useRef()
  const { handleSubmit } = useForm()
  const [initialValue, setInitialValue] = useState(true)
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const [termAccepted, setTermAccepted] = useState(false)
  const [waiting, setWaiting] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const { refinanceDetail } = tilRefinance
  const [error, setError] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [countError, setCountError] = useState(0)
  const [detail, setDetail] = useState(undefined)
  const [path, setPath] = useState()
  const isError = tilRefinanceForm && tilRefinanceForm.syncErrors

  const vehicleBrand = 'อีซูซุ'

  if (isSubmiting) dispatch(showLoader())
  else dispatch(hideLoader())

  useEffect(() => {
    const defaultValue = async () => {
      await api
        .getDefaultVehicle()
        .then((res) => setDetail(res.data))
        .catch((err) => {
          checkError(err)
          window.location.href = tilWebsiteUrl
        })
    }
    if (params.id) {
      dispatch(getVehicleDetail(params.id))
    } else {
      defaultValue()
    }
    postPageView()
  }, [])

  useEffect(() => {
    if (params.id) {
      setDetail(vehicleDetail.vehicle)
    }
  }, [vehicleDetail])

  useEffect(() => {
    if (detail && profile) {
      setPath(`/vehicle/${detail && (detail.hash_id || params.id)}`)
      requestForRefinanceDetail()
      trackRefinanceEvent(detail.vin, detail.show_til_refinance)
    }
  }, [detail, profile])

  useEffect(() => {
    if (!waiting && !refinanceDetail) {
      setError(true)
      setCountError(countError + 1)

      if (countError === maximumError) {
        setOpenModal(true)
      }

      if (errorModal && openModal) {
        errorModal.current.openModal()
      }
    }
  }, [waiting, errorModal, openModal])

  useEffect(() => {
    if (tilRefinanceForm && !tilRefinanceForm.values && initialValue) {
      if (profile) {
        change('name', profile.given_name)
        change('surname', profile.surname)
        change('mobilePhone', profile.mobile_phone)
        setInitialValue(false)
      }
    }
  }, [tilRefinanceForm, profile])

  const startTimeOptions = []
  useMemo(() => {
    let hours = 8,
      minutes = 30
    while (hours < 17) {
      startTimeOptions.push({
        label: `${paddingZeros(hours)}:${paddingZeros(minutes)}`,
        value: `${paddingZeros(hours)}${paddingZeros(minutes)}`
      })
      if (minutes === 30) {
        hours++
        minutes = 0
      } else minutes = 30
    }
  })

  const endTimeOptions = []
  useMemo(() => {
    if (startTime) {
      const startHours = +startTime.value.substr(0, 2),
        startMinutes = +startTime.value.substr(2, 2)
      let hours = startMinutes === 30 ? startHours + 1 : startHours,
        minutes = startMinutes === 30 ? 0 : 30
      while ((hours < 17 || (hours === 17 && minutes === 0)) && (hours > startHours || (hours === startHours && minutes > startMinutes))) {
        endTimeOptions.push({
          label: `${paddingZeros(hours)}:${paddingZeros(minutes)}`,
          value: `${paddingZeros(hours)}${paddingZeros(minutes)}`
        })
        if (minutes === 30) {
          hours++
          minutes = 0
        } else minutes = 30
      }
    }
  })

  const requestForRefinanceDetail = async () => {
    setWaiting(true)
    setError(false)

    if (detail.id) {
      await dispatch(
        getRefinanceDetail(
          tilAppKey,
          detail.vin,
          vehicleBrand,
          getVehicleType(detail.vehicle_model),
          detail.model_short_name,
          detail.model_code,
          getVehicleGear(detail.transmission_system),
          detail.model_year
        )
      )
        .then((res) => {
          setWaiting(false)

          // No Vehicle Error Modal
          if (res.refinanceDetail && res.refinanceDetail.status === 'fail') {
            if (noVehicleModal.current) noVehicleModal.current.openModal()
          }
        })
        .catch((error) => {
          checkError(error)
          setWaiting(false)

          // Retry Error
          setError(true)
          setCountError(countError + 1)
          if (countError + 1 === maximumError) {
            // Server Error Modal
            setOpenModal(true)
          }
        })
    }
  }

  const registerTIL = () => {
    const startHour = startTime.value.substr(0, 2),
      startMinute = startTime.value.substr(2, 2)
    const endHour = endTime.value.substr(0, 2),
      endMinute = endTime.value.substr(2, 2)

    const { name, surname, mobilePhone } = tilRefinanceForm.values

    setIsSubmiting(true)

    dispatch(
      registerTransactionContractRf(
        tilAppKey,
        refinanceDetail?.tilCustomerFlag || 'Y',
        null,
        name,
        surname,
        profile.nation_id,
        mobilePhone,
        '',
        profile.email,
        `${startHour}.${startMinute}`,
        `${endHour}.${endMinute}`,
        refinanceDetail?.financialAmount,
        '',
        vehicleBrand,
        getVehicleType(detail.vehicle_model),
        detail.model_short_name,
        detail.model_code,
        getVehicleGear(detail.transmission_system),
        detail.model_year,
        detail.vin
      )
    )
      .then((res) => {
        if (res.registerResult && res.registerResult.status === 'fail') {
          // Submit Error
          errorSubmitModal.current.openModal()
          setIsSubmiting(false)
        } else {
          setSubmitted(false)
          dispatch(showAlert(alert(200, 'til-refinance')))
          history.push(`/vehicle/${params.id || detail.hash_id}`)
        }
      })
      .catch((error) => {
        // Submit Error
        checkError(error)
        errorSubmitModal.current.openModal()
        setIsSubmiting(false)
      })
    setSubmitted(true)
  }

  const toggleCheckbox = () => {
    setTermAccepted(!termAccepted)
  }

  const getVehicleGear = (param) => {
    let gear = param
    if (param === 'MT(Manual Transmission)') {
      gear = 'ธรรมดา'
    } else if (param === 'AT(Automatic Transmission)') {
      gear = 'อัตโนมัติ'
    } else {
      gear = 'ธรรมดา'
    }
    return gear
  }

  const getVehicleType = (param) => {
    if (param === 'PICKUP') {
      return 'PU'
    } else return param
  }

  return (
    <div className='til-refinance'>
      {/** Change params.id to user_vehicle hash from BE */}
      <DetailHeader showBack defaultPath={`/vehicle/${detail && detail.hash_id}`} type='til-refinance' isUseDefault={!params.id} />
      <div className='til-refinance__body'>
        <ErrorModal
          ref={errorModal}
          label='common.modal.message.errorMessage'
          labelId='send-refinance-fail-text'
          buttonId='refinance-submit-error-ok-btn'
          confirm={() => history.push(path)}
        />
        <ErrorModal
          ref={errorSubmitModal}
          label='tilRefinance.modal.submitError'
          labelId='send-refinance-fail-text'
          buttonId='refinance-submit-error-ok-btn'
          confirm={() => history.push(path)}
        />
        <NoVehicleModal ref={noVehicleModal} redirectUrl={path} history={history} />
        <TILRefinanceBox
          refinanceDetail={refinanceDetail}
          requestForRefinanceDetail={requestForRefinanceDetail}
          waiting={waiting}
          error={error}
        />
        <TILRefinanceForm
          handleSubmit={handleSubmit(registerTIL)}
          startTimeOptions={startTimeOptions}
          startTime={startTime}
          setStartTime={setStartTime}
          endTimeOptions={endTimeOptions}
          endTime={endTime}
          setEndTime={setEndTime}
          termAccepted={termAccepted}
          toggleCheckbox={toggleCheckbox}
          submitted={submitted}
          isError={isError}
        />
      </div>
    </div>
  )
}

TILRefinance.propTypes = {
  getRefinanceDetail: PropTypes.func,
  registerTransactionContractRf: PropTypes.func,
  tilRefinanceForm: PropTypes.object,
  tilRefinance: PropTypes.object,
  vehicleDetail: PropTypes.object
}

const mapStateToProps = /* istanbul ignore next */ (state) => ({
  tilRefinanceForm: state.form.tilRefinance,
  tilRefinance: state.tilRefinance,
  profile: state.profile,
  vehicleDetail: state.vehicleDetail,
  initialValues: {
    name: state.profile.given_name,
    surname: state.profile.surname,
    mobilePhone: state.profile.mobile_phone
  }
})

const mapDispatchToProps =
  /* istanbul ignore next */
  (dispatch) =>
    bindActionCreators(
      {
        change,
        postPageView,
        getRefinanceDetail,
        registerTransactionContractRf,
        getVehicleDetail
      },
      dispatch
    )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    reduxForm({
      form: 'tilRefinance',
      enableReinitialize: true
    })(TILRefinance)
  )
)
