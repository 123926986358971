import { BANNER, INDIVIDUAL_CUSTOMER_END_OF_SUPPORT_BANNER, LOGIN_BANNER } from '../definitions'
import { showAlert } from 'store/actions/alert'
import alert from 'utils/alert'

import { apolloClient } from 'domains/common/graphql/client'
import { getUserErrorCode, isAuthenticationError, isIgnoreGraphQLError } from 'domains/common/graphql/utils'
import { ANONYMOUS_HERO_BANNERS_QUERY } from 'domains/heroBanners/graphql/queries/anonymous-hero-banners-query'
import { HERO_BANNERS_QUERY } from 'domains/heroBanners/graphql/queries/hero-banners-query'
import env from 'config/environment'
import { getAppStoreOrPlayStoreUrl } from 'utils/helper'

const getBannersSlider = () => async (dispatch) => {
  try {
    const result = await apolloClient.query({
      query: HERO_BANNERS_QUERY
    })
    const edges = result.data.customerViewer.cms.heroBanners.edges

    const userErrorCodes = edges.map(getUserErrorCode('node')).filter((code) => !!code)

    if (userErrorCodes.length > 0) {
      throw new Error(userErrorCodes[0])
    }

    const banners = edges
      // Frontend web supports only image banners.
      .filter((edge) => !!edge.node.imageUrl)
      .map((edge) => ({
        id: edge.node.id,
        image_url: edge.node.imageUrl,
        // TODO: LEOG-2897 Send title from backend as image alt text
        title: edge.node.title || edge.node.id,
        url: edge.node.webTargetUrl
      }))

    dispatch({
      type: BANNER.GET,
      total_count: banners.length,
      data: banners
    })
  } catch (error) {
    console.log('error banner', error)
    if (isAuthenticationError(error)) {
      dispatch(showAlert(alert(401)))

      return
    }

    if (isIgnoreGraphQLError(error)) {
      console.log('Ignore GraphQL Error', error)
      return
    }

    // Allow Datadog SDK to pick up this error
    console.error(error)

    dispatch(showAlert(alert(500)))
  }
}

const getLoginBanner = () => async (dispatch) => {
  try {
    const result = await apolloClient.query({
      query: ANONYMOUS_HERO_BANNERS_QUERY
    })
    const edges = result.data.cmsNamespace.anonymousHeroBanners.edges

    const userErrorCodes = edges.map(getUserErrorCode('node')).filter((code) => !!code)

    if (userErrorCodes.length > 0) {
      throw new Error(userErrorCodes[0])
    }

    const banners = edges
      // Frontend web supports only image banners.
      .filter((edge) => !!edge.node.imageUrl)
      .map((edge) => ({
        id: edge.node.id,
        image_url: edge.node.imageUrl,
        // TODO: LEOG-2897 Send title from backend as image alt text
        title: edge.node.title || edge.node.id,
        url: edge.node.webTargetUrl
      }))

    dispatch({
      type: LOGIN_BANNER.GET,
      total_count: banners.length,
      data: banners
    })
  } catch (error) {
    if (isAuthenticationError(error)) {
      dispatch(showAlert(alert(401)))

      return
    }

    if (isIgnoreGraphQLError(error)) {
      console.log('Ignore GraphQL Error', error)
      return
    }

    // Allow Datadog SDK to pick up this error
    console.error(error)

    dispatch(showAlert(alert(500)))
  }
}

const getIndividualCustomerEndOfSupportBanner = () => async (dispatch) => {
  dispatch({
    type: INDIVIDUAL_CUSTOMER_END_OF_SUPPORT_BANNER.GET,
    total_count: 1,
    data: [
      {
        id: 'terminate-myisuzu-web-banner',
        image_url: `${env.cdn}/assets/media/images/terminate-banner-1.png`,
        title: 'End of Support Banner',
        url: getAppStoreOrPlayStoreUrl()
      }
    ]
  })
}

export { getBannersSlider, getLoginBanner, getIndividualCustomerEndOfSupportBanner }
