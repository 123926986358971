import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import env from 'config/environment'
import { getToken } from 'utils/auth'

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // See: https://www.apollographql.com/docs/react/data/error-handling#graphql-error-policies
  // See: https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
  defaultOptions: {
    // apolloClient.watchQuery + useQuery
    watchQuery: {
      // Also expose raw `errors` (in addition to `error`) in response object.
      // Will not throw error.
      errorPolicy: 'all',
      // CAUTION: When use Apollo cache, it will omit any field from unknown
      // fragments. i.e., `... on UserError` will NOT work.
      // `... on CommunityPostNotFoundError` will only work when `__typename` is
      // exactly equal to `CommunityPostNotFoundError`.
      //
      // There are 3 workarounds for this:
      // - Define every possible implementation types of `UserError` in
      //   `inMemoryCacheConfig.possibleTypes`
      // - Use fragment against all implementation types instead of interface.
      //   e.g.,
      //   ```graphql
      //    mutation {
      //      someField {
      //        ... on operationSuccess {
      //          success
      //        }
      //
      //        ... on NotFoundError {
      //          code
      //          message
      //        }
      //
      //        ... on OperationNotAllowedError {
      //          code
      //          message
      //        }
      //
      //        ... on AlreadyPerformedError {
      //          code
      //          message
      //        }
      //      }
      //    }
      //   ```
      // - Set `fetchPolicy: 'no-cache'` and never use this god damn cache.
      //
      // For manual query via `apolloClient` and `useMutation`, we disabled
      // cache completely to avoid this problem, because we never need cache for
      // such scenarios. (Manual query is usually called inside Redux
      // action-creator, thus the caching will be managed by Redux instead. As
      // for `useMutation`, caching is pretty much useless for us because we
      // usually have only `success: true` in response object.)
      //
      // For `useQuery` however, sending response object from component back to
      // Redux store will waste one more React rendering cycle, thus considered
      // as anti-pattern. And more importantly, we hardly use fragment in normal
      // queries anyway. So using cache in this case should be fine and would be
      // more convenient for us.
      //
      // See: https://github.com/apollographql/apollo-client/issues/7648
      // See: https://www.apollographql.com/docs/react/data/fragments#defining-possibletypes-manually
      fetchPolicy: 'network-only'
    },
    // apolloClient.query
    query: {
      // Throw error when promise rejected.
      errorPolicy: 'none',
      fetchPolicy: 'no-cache'
    },
    // apolloClient.mutate + useMutation
    mutate: {
      // Throw error when promise rejected.
      errorPolicy: 'none',
      fetchPolicy: 'no-cache'
    }
  },
  link: from([
    setContext((_operation, previousContext) => {
      const token = getToken()

      if (token) {
        return {
          ...previousContext,
          headers: {
            ...previousContext.headers,
            authorization: `Bearer ${token}`
          }
        }
      }

      return previousContext
    }),
    createHttpLink({
      uri: `${env.apiV3}/graphql`
    })
  ])
})
