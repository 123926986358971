import { VEHICLE } from '../definitions'
import api from 'api/'
import alert from 'utils/alert'
import { showAlert } from 'store/actions/alert'
import { getProfileStat } from './profile'
import { showLoader, hideLoader } from 'store/actions/loader'
import { getDisplayCompany } from 'utils/helper'
import { apiV3 } from 'api/index-v3'
import env from 'config/environment'

/**
 * getVehicles - use to get vehicles list data
 *
 * @param {boolean} [isReload=true] - is reload data
 * @param {string} [q=''] - query
 * @param {number} [ob=1] - orderBy
 * @param {number} [off=0] - offset
 * @param {number} [lt=30] - limit
 * @param {number} [fav=0] - favorite type
 * @param {string} vt - vehicle type
 * @param {string} c - controller
 * @param {string} [vm='all'] - vehicle model
 * @param {boolean} [nx=false] - next maintenance
 * @param {boolean} [evo=false] - N-series excellency
 * @returns {Promise} - response data
 */
const getVehicles = (isReload = true, q = '', ob = 1, off = 0, lt = 30, fav = 0, vt = 0, c, vm = 'all', nx = false, evo = false) => {
  return (dispatch) => {
    if (isReload) dispatch(showLoader())

    return new Promise(function (resolve, reject) {
      api
        .getVehicles({ q, ob, off, lt, fav, vt, c, vm, nx, evo })
        .then((res) => {
          const total_count = parseInt(res.headers['x-total-count']) || res.data.length
          const has_only_cv = (res.headers['has-only-cv'] === 'true' ? true : false) || null

          let data = res.data
          if (fav === 2) {
            data = res.data.map((d) => {
              d.is_checked = true
              return d
            })
          }

          dispatch({
            type: VEHICLE.GET,
            total_count,
            has_only_cv,
            data,
            isReload,
            limit: lt,
            offset: off
          })

          if (isReload) dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} id
 */
const getVehicleDetail =
  (id, history, dispatchLoader = true) =>
  async (dispatch) => {
    try {
      if (dispatchLoader) dispatch(showLoader())
      // TODO: Next step on vehicle detail on Backend V1 should we remove field coupon from response
      const [{ data }, { data: insuranceInfo }] = await Promise.all([api.getVehicleDetail(id), api.getVehicleInsurance(id)])

      const {
        vehicle: { ...vehicleDetails }
      } = data

      let {
        vehicle: { service_coupons = [], cash_coupons = [] }
      } = data

      let responseCouponsFromServiceV3 = null
      if (vehicleDetails?.id) {
        responseCouponsFromServiceV3 = await apiV3.getCouponsByVehicleHashId(vehicleDetails?.id)
      }

      if (
        responseCouponsFromServiceV3 &&
        responseCouponsFromServiceV3.data?.service_coupons &&
        responseCouponsFromServiceV3.data?.cash_coupons
      ) {
        service_coupons = responseCouponsFromServiceV3.data.service_coupons
        cash_coupons = responseCouponsFromServiceV3.data.cash_coupons
      }

      const couponVehicle = {
        id: vehicleDetails.id,
        vin: vehicleDetails.vin,
        license_plate: vehicleDetails.license_plate,
        chassis_no: vehicleDetails.chassis_no,
        engine_no: vehicleDetails.engine_no,
        delivery_date: vehicleDetails.delivery_date,
        next_maintenance_date: vehicleDetails.next_maintenance_date,
        model_code: vehicleDetails.model_code,
        model: vehicleDetails.model,
        vehicle_type: vehicleDetails.vehicle_type,
        user_vehicle: {
          name: data.name,
          id: data.id,
          image_url: data.image_url,
          is_favorite: data.is_favorite,
          status: data.status,
          owner_id: data.owner_id
        }
      }

      const serviceCoupons = service_coupons.map(
        /*istanbul ignore next 2019-11-22*/ (cp) => {
          return Object.assign({}, cp, {
            coupon_info: {
              name: cp.type.name_th,
              type: cp.type.type,
              coupon_logo: cp.type.coupon_logo,
              marketing_logo: cp.type.coupon_marketing_logo,
              campaign_code: cp.type.campaign_code,
              title: cp.detail.title_th,
              benefits: cp.detail.benefit_th,
              terms: cp.detail.terms_th,
              remarks: '',
              coupon_category: cp.type.coupon_category,
              value: parseInt(cp.value) || 0,
              license_plate: vehicleDetails.license_plate,
              owner_id:
                (cp.user && cp.user.national_id) ||
                (cp.user && cp.user.passport_no) ||
                (cp.user && cp.user.company_reg_id) ||
                (cp.corporate && cp.corporate.company_reg_id),
              asterisk: false,
              from_vehicleDetail: true //Used to differentiate flow on coupon click under coupon item component
            },
            vehicle: {
              ...couponVehicle
            }
          })
        }
      )

      const cashCoupons = cash_coupons.map(
        /*istanbul ignore next 2019-11-22*/ (cp) => {
          return Object.assign({}, cp, {
            coupon_info: {
              name: cp.type.name_th,
              type: cp.type.type,
              coupon_logo: cp.type.coupon_logo,
              cash_coupon_number: cp.cash_coupon_number,
              marketing_logo: cp.type.coupon_marketing_logo,
              campaign_code: cp.type.campaign_code,
              title: cp.detail.title_th,
              benefits: cp.detail.benefit_th,
              terms: cp.detail.terms_th,
              remarks: '',
              coupon_category: cp.type.coupon_category,
              value: parseInt(cp.value) || 0,
              license_plate: cp.vehicle && cp.vehicle.license_plate,
              owner_id:
                (cp.user && cp.user.national_id) ||
                (cp.user && cp.user.passport_no) ||
                (cp.user && cp.user.company_reg_id) ||
                (cp.corporate && cp.corporate.company_reg_id),
              asterisk: false,
              from_vehicleDetail: true //Used to differentiate flow on coupon click under coupon item component
            },
            vehicle: {
              ...couponVehicle
            }
          })
        }
      )

      const vehicleDetail = {
        ...data,
        vehicle: {
          ...vehicleDetails,
          serviceCoupons,
          cashCoupons,
          insuranceInfo
        }
      }

      dispatch({
        type: VEHICLE.DETAIL,
        vehicleDetail
      })
    } catch (err) {
      if (err && err.response) {
        dispatch(showAlert(alert(err.response.status, err.response.data.error_code)))
      }
    } finally {
      if (dispatchLoader) {
        dispatch(hideLoader())
      }
    }
  }

/**
 *
 * @param {*} id
 * @param {*} payload
 */
const editVehicleDetail = (id, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      api
        .editVehicleDetail(id, payload)
        .then(() => {
          return dispatch(getVehicleDetail(id))
        })
        .then(
          /*istanbul ignore next 2019-11-22*/ (res) => {
            resolve(res)
          }
        )
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} vin
 */
const searchVehicleVin = (vin) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())

      api
        .searchVehicleVin(vin)
        .then((res) => {
          dispatch({
            type: VEHICLE.SEARCH,
            vehicleDetail: res.data
          })
          dispatch(hideLoader())
          resolve()
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 * @param {*} payload
 */
const activateVehicle = (payload) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api
      .activateVehicle(payload)
      .then(() => {
        return dispatch(getProfileStat())
      })
      .then(() => {
        dispatch(getVehicleLength())
      })
      .then(() => {
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 * @param {*} id
 * @param {*} isFavorite
 */
const toggleFavorite = (id, isFavorite) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api
      .toggleVehicleFavorite(id, isFavorite)
      .then((res) => {
        dispatch({
          type: VEHICLE.FAV,
          id: id,
          value: res.data.is_favorite
        })
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 *
 * @param {*} id
 * @param {*} controllerId
 */
const addController = (id) => (dispatch, getState) => {
  const {
    vehicleDetail: { controllers },
    controllersList: { data }
  } = getState()

  const controller = data.find(({ id: controllerId }) => controllerId === id)

  dispatch({
    type: VEHICLE.UPDATE_CONTROLLERS,
    data: [...controllers, controller]
  })
}

/**
 *
 * @param {*} id
 */
const clearController = (id) => (dispatch, getState) => {
  const {
    vehicleDetail: { controllers }
  } = getState()

  const _controllers = controllers.filter(({ id: controllerId }) => id !== controllerId)

  dispatch({
    type: VEHICLE.UPDATE_CONTROLLERS,
    data: _controllers
  })
}

/**
 *
 * @param {*} id
 * @param {*} payload
 */
const deleteVehicle = (id, payload) => {
  return (dispatch) => {
    dispatch(showLoader())

    return api
      .deleteVehicle(id, payload)
      .then(() => {
        dispatch({
          type: VEHICLE.DELETE,
          id: id
        })
        dispatch(hideLoader())
      })
      .catch((err) => {
        dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
        dispatch(hideLoader())
      })
  }
}

/**
 * assign controllers to vehicles
 *
 * @param {array} vehicleIds
 * @param {object} reasons
 * @returns {Promise} dispatch function
 */
const deleteVehicles = (vehicleIds, reasons) => async (dispatch) => {
  dispatch(showLoader())

  let requestPayload = {
    vehicles: vehicleIds,
    reason: reasons.reason
  }

  if (reasons.remarks) {
    requestPayload.remarks = reasons.remarks
  }

  try {
    await api.deleteVehicles(requestPayload)
    dispatch(getVehicleLength())
    dispatch(uncheckAll())
    dispatch(showAlert(alert(200, 'delete-vehicles')))
  } catch (err) {
    dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'delete-vehicles')))
  } finally {
    dispatch(hideLoader())
  }
}

/**
 * assign controllers to vehicles
 *
 * @param {array} controllers
 * @param {array} vehicles
 * @returns {Promise} dispatch function
 */
const assignControllers = (controllers, vehicles) => async (dispatch) => {
  dispatch(showLoader())

  const payload = {
    controllers,
    vehicles
  }
  try {
    await api.assignVehiclesControllers(payload)

    dispatch(showAlert(alert(200, 'vehicle-assign_controller')))
    dispatch(uncheckAll())
    dispatch(hideLoader())
  } catch (err) {
    dispatch(
      showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'vehicle-assign_controller'))
    )
    dispatch(hideLoader())
    throw err
  }
}

/**
 * unassign controllers to vehicles
 *
 * @param {array} controllers
 * @param {array} vehicles
 * @returns {Promise} dispatch function
 */
const unassignControllers = (controllers, vehicles) => async (dispatch) => {
  dispatch(showLoader())

  const payload = {
    controllers,
    vehicles
  }

  try {
    await api.unassignVehiclesControllers(payload)

    dispatch(showAlert(alert(200, 'vehicle-unassign_controller')))
    dispatch(uncheckAll())
    dispatch(hideLoader())
  } catch (err) {
    dispatch(
      showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, 'vehicle-unassign_controller'))
    )
    dispatch(hideLoader())
    throw err
  }
}

/**
 *
 * @param {*} id
 */
const toggleCheck = (id) => {
  return (dispatch) => {
    dispatch({
      type: VEHICLE.CHECK,
      id
    })
  }
}

/**
 *
 */
const uncheckAll = () => {
  return (dispatch) => {
    dispatch({
      type: VEHICLE.UNCHECK_ALL
    })
  }
}

/** Check all vehicle that user have.
 *
 * @returns {Promise} dispatch function
 */
const checkAll = () => {
  return (dispatch) => {
    dispatch({
      type: VEHICLE.CHECK_ALL
    })
  }
}

/**
 *
 */
const getVehiclesSlider = () => {
  return (dispatch) => {
    return api.getVehicles({ q: '', ob: 1, off: 0, lt: 100, vt: 0, nx: true, vm: 'all' }).then((res) => {
      const total_count = res.data.length
      // const data = filterVehicleReachNextMaintenance(res.data)
      const data = res.data

      dispatch({
        type: VEHICLE.SLIDER,
        total_count,
        data
      })
    })
  }
}

/**
 *
 */
const getVehicleServiceCenter = (id) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getVehicleServiceCenters(id)
        .then((res) => {
          dispatch({
            type: VEHICLE.GET_SC,
            total_count: res.data.length,
            data: res.data
          })
          dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 * Download vehicles - download excel file
 *
 * @returns {Promise}
 */
const downloadVehicles = () => async (dispatch, getState) => {
  dispatch(showLoader())

  const { profile: { corporate } = {} } = getState()
  const displayCompany = getDisplayCompany(corporate).replace(/\s/g, '_')

  try {
    const { data: { data, type } = {} } = await api.downloadVehicles()

    const a = document.createElement('a')
    a.href = `data:${type};base64,${data}`
    a.download = `${displayCompany}.xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error) {
    dispatch(showAlert(error && error.response && alert(error.response.status, error.response.data.error_code)))
  } finally {
    dispatch(hideLoader())
  }
}

const deleteVehicleImage = (id, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())

      let requestPayload = {
        headers: {
          'Content-Type': 'application/json'
        },
        data: payload
      }
      return api
        .deleteVehicleImage(id, requestPayload)
        .then(() => {
          dispatch(hideLoader())
          dispatch(showAlert(alert(200, 'vehicle-delete-image')))
          resolve()
        })
        .then(() => {
          dispatch(getVehicleDetail(id))
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const setCoverImage = (id, payload) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(showLoader())
      return api
        .setCoverImage(id, payload)
        .then(() => {
          dispatch(hideLoader())
          dispatch(showAlert(alert(200, 'vehicle-setCover-image')))
          resolve()
        })
        .then((res) => {
          dispatch(getVehicleDetail(id))
        })
        .catch((err) => {
          dispatch(showAlert(err && err.response ? alert(err.response.status, err.response.data.error_code) : alert(0, '')))
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

/**
 *
 */
const getVehicleInsurance = (id) => {
  return (dispatch) => {
    dispatch(showLoader())

    return new Promise((resolve, reject) => {
      api
        .getVehicleInsurance(id)
        .then((res) => {
          dispatch({
            type: VEHICLE.GET_INSURANCE,
            data: res.data
          })
          dispatch(hideLoader())
          resolve(res)
        })
        .catch((err) => {
          dispatch(hideLoader())
          reject(err)
        })
    })
  }
}

const getVehicleLength = () => {
  return (dispatch) => {
    return api.getVehicleLength().then((res) => {
      const length = res.data.vehicle_length
      const hashId = res.data.vehicle_hash
      dispatch({
        type: VEHICLE.GET_LENGTH,
        length,
        hashId
      })
    })
  }
}

export {
  getVehicles,
  getVehicleDetail,
  editVehicleDetail,
  searchVehicleVin,
  toggleFavorite,
  toggleCheck,
  uncheckAll,
  activateVehicle,
  addController,
  assignControllers,
  unassignControllers,
  clearController,
  deleteVehicle,
  deleteVehicles,
  checkAll,
  getVehiclesSlider,
  downloadVehicles,
  getVehicleServiceCenter,
  deleteVehicleImage,
  setCoverImage,
  getVehicleInsurance,
  getVehicleLength
}
